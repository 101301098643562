import React from 'react';
// import sections
// import Cta from '../components/sections/Cta';
import SupportSection from '../components/sections/SupportSection';

const Support = () => {

  return (
    <>
      <SupportSection className="illustration-section-01" />
    </>
  );
}

export default Support;